import React from 'react'
import { motion } from 'framer-motion';
const NotFound = () => {
  return (
    <motion.div
      className="not-found-wrapper"
      initial={{ y: "-100vh" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      exit={{ y: "100vh" }}
    >
      <section className="page-sction">
        <div className="page-Heading-cover">
          <h1 className='page-heading'>Page Not found</h1>
        </div>
      </section>
    </motion.div>
  )
}
export default NotFound
