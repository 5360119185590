import React from 'react'
const Progress = (props) => {
    const data = props.data;
    return (
        <>
            <div className="skils-wrapper">
                {
                    data.map((design, index) => {
                        const { SkillName, SkillNum} = design;
                        return(<div className="progress-item" key={index}>
                            <div className="clear-test">
                                <span className='pregressName'>{SkillName}</span>
                                <span className='pregressNum'>{SkillNum}</span>
                            </div>
                            <div className='progress'>
                                <div className='progress-bar' style={{ width: `${SkillNum}%` }} role="progressbar" aria-label="Basic example" aria-valuenow={design.SkillNum} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Progress
