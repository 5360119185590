import React from 'react'

const PageHeading = (props) => {
    const{heading, Subheading}=props;
    return (
        <>
            <div className="page-Heading-cover">
                <h1 className='page-heading'>{heading}</h1>
                <span className='page-sub-heading'>{Subheading}</span>
            </div>
        </>
    )
}

export default PageHeading
