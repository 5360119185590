import { React, useState } from 'react'
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import SidebarItems from './tools/SidebarItems';
import Subheading from './tools/Subheading';
const Sidebar = (props) => {
  const [DomClass, setDomClass] = useState(false);
  const Siderbar = () => {
    setDomClass(!DomClass);
  }
  const recentPosts = ['Creative and Innovative Navigation Designs', 'Why I Switched to Sketch For UI Design', 'An Overview of E-Commerce Platforms', 'Designing the Perfect Feature Comparison Table', 'Best Practices for Animated Progress Indicators'];
  const Categories = ['Design', 'E-Commerce', 'UI', 'Uncategorized', 'WordPress'];
  const Archives = ['June 2020'];
  const Meta = ['Log in', 'Entries feed', 'Comments feed', 'Uncategorized', 'WordPress.org'];
  return (
    <>
      <div className={`sideBarOpener ${DomClass ? "open" : "close"}`} onClick={()=>{props.callPrentFun();Siderbar()}} >
        {
          DomClass ? <RxDoubleArrowRight /> : <RxDoubleArrowLeft />
        }

      </div>
      <div className={`siderbar ${DomClass ? "open" : "close"}`}>
        <div className="sidebar-items">
          <Subheading title="Recent Posts" />
          <SidebarItems data={recentPosts} />
        </div>
        <div className="sidebar-items">
          <Subheading title="Archives" />
          <SidebarItems data={Archives} />
        </div>
        <div className="sidebar-items">
          <Subheading title="Categories" />
          <SidebarItems data={Categories} />
        </div>
        <div className="sidebar-items">
          <Subheading title="Meta" />
          <SidebarItems data={Meta} />
        </div>

      </div>
    </>
  )
}
export default Sidebar
