import React from 'react';
import PageHeading from './tools/PageHeading';
import Progress from './tools/Progress';
import Subheading from './tools/Subheading';
import { motion } from 'framer-motion';
import Timeline from './tools/Timeline';
import Knowledge from './tools/Knowledge';
const Resume = (props) => {
  const designSkil = [
    {
      SkillName: "Web Design",
      SkillNum: 65,
    },
    {
      SkillName: "Print Design",
      SkillNum: 20,
    },
    {
      SkillName: "Logo Design",
      SkillNum: 60,
    },
    {
      SkillName: "Graphic Design",
      SkillNum: 70,
    }
  ];
  const codeSkil = [
    {
      SkillName: "HTML/CSS",
      SkillNum: 100,
    },
    {
      SkillName: "JavaScript",
      SkillNum: 85,
    },
    {
      SkillName: "jQuery",
      SkillNum: 85,
    },
    {
      SkillName: "PHP",
      SkillNum: 90,
    },
    {
      SkillName: "WordPress",
      SkillNum: 95,
    },
    {
      SkillName: "Laravel",
      SkillNum: 90,
    },
    {
      SkillName: "React",
      SkillNum: 60,
    },
    {
      SkillName: "MERN",
      SkillNum: 40,
    }
  ];
  const experiance = [
    {
      id: 1,
      tStatus: "",
      tName: "Atom Creatives",
      tDate: "2012 - 2013",
      tTitle: "Intern",
      tText: "HTML, CSS, PHP, jQuery/Javascript, Bootstrap",
    },
    {
      id: 2,
      tStatus: "",
      tName: "Work In Station",
      tDate: "2013 - 2015",
      tTitle: "Junior Web developer",
      tText: "HTML, CSS, PHP, jQuery/Javascript, Bootstrap",
    },
    {
      id: 3,
      tStatus: "",
      tName: "Ocian Web",
      tDate: "2015 - 2017",
      tTitle: "Mid Web developer",
      tText: "WordPress Theme/Plugin, HTML, CSS, PHP, jQuery/Javascript For WordPress.org theme pro / Client Projects",
    },
    {
      id: 4,
      tStatus: "",
      tName: "Responsive Pixel",
      tDate: "2017 - 2020",
      tTitle: "Senior Web developer",
      tText: "WordPress Theme/Plugin, HTML, CSS, PHP, jQuery/Javascript For WordPress.org pro / Envato Themes / Client projects",
    },
    {
      id: 5,
      tStatus: "highlighted",
      tName: "Upperlatives Solutions",
      tDate: "2020 - Current",
      tTitle: "Lead Web developer",
      tText: "WordPress Theme/Plugin, HTML, CSS, PHP, jQuery/Javascript, Ajax, Laravel, Server Management, SEO, Project Management",
    }
  ];
  const Education = [
    {
      id: 1,
      tStatus: "",
      tName: "NIIT",
      tDate: "2010 - 2012",
      tTitle: "Web Development ",
      tText: "Basic :- HTML, CSS, PHP, MySQL, Javascript, jQuery, Photoshop",
    },
    {
      id: 2,
      tStatus: "",
      tName: "SELF",
      tDate: "2012 - 2014",
      tTitle: "Deep PHP / MySQL",
      tText: "Functions, Object-Oriented (Live Project)",
    },
    {
      id: 2,
      tStatus: "",
      tName: "SELF",
      tDate: "2012 - 2014",
      tTitle: "WordPress",
      tText: "hooks, callback, shortcodes, templates, customization, theme check",
    },
    {
      id: 3,
      tStatus: "",
      tName: "SELF",
      tDate: "2014 - 2016",
      tTitle: "Javascript/jQuery",
      tText: "syntax, Functions, Object-Oriented Programming, DOM manipulation",
    },
    {
      id: 4,
      tStatus: "",
      tName: "NIIT",
      tDate: "2016 - 2017",
      tTitle: "Laravel",
      tText: "Blade templating engine, Eloquent ORM, MVC, Migrations and seeds, hashing Security, Best practices ( clean, maintainable, and scalable ) code.",
    },
    {
      id: 5,
      tStatus: "highlighted",
      tName: "SELF",
      tDate: "2020 - Current",
      tTitle: "React",
      tText: "JSX, React lifecycle, State management, Asynchronous programming, reusable components, Best practices ( clean, maintainable, and scalable ) code.",
    }
  ];
  const knowledges = ['Marketing', 'Print', 'Digital Design', 'Social Media', 'Time Management', 'Communication', 'Problem-Solving', 'Social Networking', 'Flexibility']
  return (
    <motion.div
      className="port-wrapper"
      initial={{ y: "-100vh" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      exit={{ y: "100vh" }}
    >
      <section className="page-sction">
        <PageHeading heading="Resume" Subheading="9 Years of Experience" />
        <div className="resume-main-cover">
          <div className="resume-first">
            <Subheading title="Education" />
            <Timeline data={Education} />
            <Subheading title="Experience" />
            <Timeline data={experiance} />
          </div>
          <div className="resume-sec">
            <Subheading title="Design Skills" />
            <Progress data={designSkil} />
            <Subheading title="Coding Skills" />
            <Progress data={codeSkil} />
            <Subheading title="Knowledges" />
            <Knowledge data={knowledges} />
            <Subheading title="Certificates" />
            <div className="cetificates-wraper">
              <div className="certificate-item">
                <div className="certi-logo">
                  <img decoding="async" src={props.imgsrc} alt="logo" />
                </div>
                <div className="certi-content">
                  <div className="certi-title">
                    <h4>Psyhology of Intertnation Design</h4>
                  </div>
                  <div className="certi-id">
                    <span>Membership ID: XXXX</span>
                  </div>
                  <div className="certi-date">
                    <span>22 September 2020</span>
                  </div>
                </div>
              </div>
              <div className="certificate-item">
                <div className="certi-logo">
                  <img decoding="async" src={props.imgsrcT} alt="logo" />
                </div>
                <div className="certi-content">
                  <div className="certi-title">
                    <h4>Psyhology of Intertnation Design</h4>
                  </div>
                  <div className="certi-id">
                    <span>Membership ID: XXXX</span>
                  </div>
                  <div className="certi-date">
                    <span>22 September 2020</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default Resume
