import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Link } from 'react-router-dom'
const MobileHeader = forwardRef((props, ref) => {
    const [Mclass, setMclass] = useState(false);
    useImperativeHandle(ref, () => {
        return {
            clearHam: Mmenu,
        }
    });
    const Mmenu = () => {
        setMclass(!Mclass);
        document.querySelector(".header").classList.toggle("open");
    }
    return (
        <>
            <div className='mHeader-wrapper'>
                <div className="mobile-log">
                    <Link to="/"><img src={props.imgsrc} alt="logo" /></Link>
                </div>
                <div className={`mNavopenner ${Mclass ? "open" : "close"}`} onClick={() => { Mmenu(); props.callPrentFun(); }}>
                    <span className='navLine'></span>
                </div>
            </div>
        </>
    )
})

export default MobileHeader
