import React from 'react';
import { motion } from 'framer-motion';
// import Isotope from 'isotope-layout';
const Portfolio = () => {
  // componentDidMount() {
  //   this.isotope = new Isotope( '.grid', {
  //     // options...
  //   });
  // }
    return (
        <motion.div
            className="port-wrapper"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            exit={{ y: "100vh" }}
        >
            <section className="page-sction">
                <div className="page-Heading-cover">
                    <h1 className='page-heading'>Portfolio</h1>
                    <span className='page-sub-heading'>My Works</span>
                </div>
            </section>
        </motion.div>
    )
}

export default Portfolio
