import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { FaStore, FaPencilAlt, FaLaptopCode, FaFlag, FaHeart, FaClock, FaStar } from "react-icons/fa";
import { motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';
const Home = (props) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  useEffect(() => {
    function handleMouseMove(event) {
      const { clientX, clientY } = event;
      const { top, left } = imageRef.current.getBoundingClientRect();
      const centerX = left + (imageRef.current.offsetWidth / 2);
      const centerY = top + (imageRef.current.offsetHeight / 2);
      const x = (centerX - clientX) / 30;
      const y = (centerY - clientY) / 30;
      setCoords({ x, y });
    }

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const imageRef = useRef(null);
  return (
    <motion.div
      className="Home-wrapper"
      initial={{ y: "-100vh" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      exit={{ y: "100vh" }}
    >
      <section className='heroarea'>
        <div className="hero-text">
          <h5 className='heroSubHeading'>
            <Typewriter
              options={{
                strings: ['Freelancer', 'Backend  Developer', 'Front - End Developer'],
                autoStart: true,
                loop: true,
              }}
            />
          </h5>
          <h1 className='heroTitle'><span>Hi, i'am</span> Hriday Chaudhary</h1>
          <p className='heroText'>Praesent sed aliquam arcu, non accumsan neque. In odio ante, vulputate ac magna vel,
            pharetra lobortis quam. Duis enim tortor, egestas et felis id, lobortis malesuada magna.
            Nunc sit amet sagittis nisi, eu semper nisl. Cras ut dictum nisl. Donec tincidunt eget orci.</p>
          <div className="herobtn">
            <Link className='btn btn-cs-primary' to="/">Download CV</Link>
            <Link className='btn btn-cs-sec' to="/">Contact</Link>
          </div>
        </div>
        <div className="hero-bg">
          <div className="heroImg" ref={imageRef} style={{ backgroundImage: `url(${props.imgsrc})`, transform: `translate(${coords.x}px, ${coords.y}px)`}}></div>
        </div>
      </section>
      <section className="watToDo">
        <div className="section-title">
          <h2>What I Do</h2>
        </div>
        <div className='doLists'>
          <div className="doList">
            <div className="info-icon-area">
              <FaStore />
            </div>
            <div className="info-content-area">
              <h3 className='info-heading'>Ecommerce</h3>
              <p className='info-text'>
                Ecommerce
                Pellentesque pellentesque, ipsum sit amet auctor accumsan, odio tortor bibendum massa, sit amet ultricies ex lectus scelerisque nibh. Ut non sodales.</p>
            </div>
          </div>
          <div className="doList">
            <div className="info-icon-area">
              <FaPencilAlt />
            </div>
            <div className="info-content-area">
              <h3 className='info-heading'>Copywriting</h3>
              <p className='info-text'>
                Pellentesque pellentesque, ipsum sit amet auctor accumsan, odio tortor bibendum massa, sit amet ultricies ex lectus scelerisque nibh. Ut non sodales.</p>
            </div>
          </div>
          <div className="doList">
            <div className="info-icon-area">
              <FaLaptopCode />
            </div>
            <div className="info-content-area">
              <h3 className='info-heading'>Web Design</h3>
              <p className='info-text'>
                Pellentesque pellentesque, ipsum sit amet auctor accumsan, odio tortor bibendum massa, sit amet ultricies ex lectus scelerisque nibh. Ut non sodales.</p>
            </div>
          </div>
          <div className="doList">
            <div className="info-icon-area">
              <FaFlag />
            </div>
            <div className="info-content-area">
              <h3 className='info-heading'>Management</h3>
              <p className='info-text'>
                Pellentesque pellentesque, ipsum sit amet auctor accumsan, odio tortor bibendum massa, sit amet ultricies ex lectus scelerisque nibh. Ut non sodales.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="testmonial">
        <div className="section-title">
          <h2>Testmonial</h2>
        </div>
        <div className="testiLists">
          <div className='testiList'>
            <div className="testi-img">
              <img src={props.imgsrcT} alt="Testimonials" />
            </div>
            <div className="testi-text">
              <p>Vivamus at molestie dui, eu ornare orci. Curabitur vel egestas dolor. Nulla condimentum nunc sit amet urna tempus finibus. Duis mollis leo id ligula pellentesque, at vehicula dui ultrices.</p>
              <h4 className='testi-heading'>Hriday chaudhary</h4>
              <span className='testi-sub-heading'>Omini source</span>
            </div>
          </div>
          <div className='testiList'>
            <div className="testi-img">
              <img src={props.imgsrcT} alt="Testimonials" />
            </div>
            <div className="testi-text">
              <p>Vivamus at molestie dui, eu ornare orci. Curabitur vel egestas dolor. Nulla condimentum nunc sit amet urna tempus finibus. Duis mollis leo id ligula pellentesque, at vehicula dui ultrices.</p>
              <h4 className='testi-heading'>Hriday chaudhary</h4>
              <span className='testi-sub-heading'>Tesla Electro</span>
            </div>
          </div>
        </div>
      </section>
      <section className="priceing">
        <div className="section-title">
          <h2>Pricing</h2>
        </div>
        <div className="pricing-lists">
          <div className="pricing-list">
            <h4>Silver</h4>
            <div className='price'>$10 <span className='priceSmal'>/hour</span></div>
            <Link to="/" className='btn btn-cs-sec'>Free Try</Link>
            <span className='program'>HTML</span>
            <span className='program'>CSS</span>
            <span className='program'>JQuery</span>
            <span className='program'>PHP</span>
            <span className='program'>WordPress</span>
            <span className='program'>SEO</span>
          </div>
          <div className="pricing-list">
            <h4>Gold</h4>
            <div className='price'>$15 <span className='priceSmal'>/hour</span></div>
            <Link to="/" className='btn btn-cs-sec'>Free Try</Link>
            <span className='program'>HTML</span>
            <span className='program'>CSS</span>
            <span className='program'>JQuery</span>
            <span className='program'>PHP</span>
            <span className='program'>WordPress</span>
            <span className='program'>SEO</span>
            <span className='program'>Laravel</span>
            <span className='program'>Java Script</span>
          </div>
          <div className="pricing-list">
            <h4>Platinum</h4>
            <div className='price'>$25 <span className='priceSmal'>/hour</span></div>
            <Link to="/" className='btn btn-cs-sec'>Free Try</Link>
            <span className='program'>HTML</span>
            <span className='program'>CSS</span>
            <span className='program'>JQuery</span>
            <span className='program'>PHP</span>
            <span className='program'>WordPress</span>
            <span className='program'>SEO</span>
            <span className='program'>Laravel</span>
            <span className='program'>Java Script</span>
            <span className='program'>React</span>
            <span className='program'>MERN</span>
          </div>
        </div>
      </section>
      <section className="fact">
        <div className="section-title">
          <h2>Fun Facts</h2>
        </div>
        <div className="fatc-lists">
          <div className="fatc-list">
            <div className="fatc-list-wrap">
              <div className="fatc-list-icon">
                <FaHeart />
              </div>
              <div className="fatc-list-cont">
                <h3 className='fact-number'>578</h3>
                <span>Happy Clients</span>
              </div>
            </div>
          </div>
          <div className="fatc-list">
            <div className="fatc-list-wrap">
              <div className="fatc-list-icon">
                <FaClock />
              </div>
              <div className="fatc-list-cont">
                <h3 className='fact-number'>4,780</h3>
                <span>Working Hours</span>
              </div>
            </div>
          </div>
          <div className="fatc-list">
            <div className="fatc-list-wrap">
              <div className="fatc-list-icon">
                <FaStar />
              </div>
              <div className="fatc-list-cont">
                <h3 className='fact-number'>15</h3>
                <span>Awards Won</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default Home
