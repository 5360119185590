import React from 'react'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeSquare } from "react-icons/fa";
import { motion } from 'framer-motion';
const Contact = () => {
    return (
        <motion.div
            className='contac-wrapper'
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            exit={{ y: "100vh" }}
        >
            <section className="page-sction">
                <div className="page-Heading-cover">
                    <h1 className='page-heading'>Contact</h1>
                    <span className='page-sub-heading'>Get in Touch</span>
                </div>
                <div className="contact-wrapper">
                    <div className="conatct-link-wrap">
                        <div className="conatct-link">
                            <FaMapMarkerAlt />
                            <h4>San Francisco</h4>
                        </div>
                        <div className="conatct-link">
                            <FaPhoneAlt />
                            <h4>9811117708</h4>
                        </div>
                        <div className="conatct-link">
                            <FaEnvelopeSquare />
                            <h4>hridaychaudhary03@gmail.com</h4>
                        </div>
                    </div>
                    <div className="form-cover">
                        <div className="section-title">
                            <h2>How Can I Help You?</h2>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-6">
                                    <input type="text" className="form-control" placeholder="First name" name="fName"  />
                                    
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control" placeholder="Last name" name='lName'  />
                         
                                </div>
                                <div className="col-12">
                                    <input type="email" className="form-control" placeholder="Email Address" name='email'  />
                                   
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" placeholder='Subject' name='subject'  />
                                </div>
                                {/* <div class="col-12">
                                    <textarea className="form-control" rows="5" name='message' register={{ ...register("message") }}></textarea>
                                </div> */}
                            </div>
                            <button type="submit" className="btn btn-primary">Send</button>
                        </form>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default Contact
