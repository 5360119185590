import React, { useState } from 'react';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Sidebar from './components/pages/Sidebar';
import { BrowserRouter as Router } from 'react-router-dom'
import image from './img/profile2.jpg';
import './style/Main.css';
import './style/responsive.css';
import AnimatedRoutes from './AnimatedRoutes';
const Main = () => {
  const [DomClass, setDomClass] = useState(false);
  const [MenuClass, setMenuClass] = useState(false);
  const Siderbar = () => {
    setDomClass(!DomClass);
  }
  const MenuOpen = () => {
    setMenuClass(!MenuClass);
  }
  return (
    <Router>
      <div className='mainBody'>
        <Header imgsrc={image} callPrentFun={() => MenuOpen()} />
        <div className={`mainContent ${DomClass ? "sidebaropen" : "sidebarclose"} ${MenuClass ? "Menuopen" : "Menuclose"}`}>
          <AnimatedRoutes />
          <Footer />
        </div>
        <Sidebar callPrentFun={() => Siderbar()} />
      </div>
    </Router>
  )
}
export default Main

