import React, { useRef } from 'react'
import { Link, NavLink } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
const Header = (props) => {
    const childfun = useRef();
    return (
        <>
            <MobileHeader ref={childfun} imgsrc={props.imgsrc} callPrentFun={() => props.callPrentFun()} />
            <div className="header" >
                <div className="site-cart">
                    <div className='headerBox'>
                        <div className="profileBlock">
                            <Link to="/"><img src={props.imgsrc} alt="profile2" /></Link>
                        </div>
                        <Link to="/" className='pName'><h3>Hriday Chaudhary</h3></Link>
                        <Link to="/" className='pSubName'><h5>Web Developer</h5></Link>
                        <ul className='social-icon'>
                            <li><a href="https://www.facebook.com/itsmehriday" target="blank"><FaTwitter /></a></li>
                            <li><a href="https://www.facebook.com/itsmehriday" target="blank"><FaFacebookF /></a></li>
                            <li><a href="https://www.instagram.com/itsmehriday" target="blank"><FaInstagram /></a></li>
                        </ul>
                    </div>
                    <ul className="headerLists">
                        <li className='headerLists' onClick={()=>{childfun.current.clearHam(); props.callPrentFun();}}>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li className='headerLists' onClick={()=>{childfun.current.clearHam(); props.callPrentFun();}}>
                            <NavLink to="/resume">Resume</NavLink>
                        </li>
                        <li className='headerLists' onClick={()=>{childfun.current.clearHam(); props.callPrentFun();}}>
                            <NavLink to="/portfolio">Portfolio</NavLink>
                        </li>
                        <li className='headerLists' onClick={()=>{childfun.current.clearHam(); props.callPrentFun();}}>
                            <NavLink to="/blog">Blog</NavLink>
                        </li>
                        <li className='headerLists' onClick={()=>{childfun.current.clearHam(); props.callPrentFun();}}>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div >
        </>
    )
}

export default Header
