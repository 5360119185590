import React from 'react'
import Home from './components/pages/Home';
import Resume from './components/pages/Resume';
import Portfolio from './components/pages/Portfolio';
import Contact from './components/pages/Contact';
import NotFound from './components/pages/NotFound';
import image from './img/profile2.jpg';
import imaAbout from './img/about2.jpg';
import JaueryImg from './img/client-1.png';
import Envato from './img/client-3.png';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
const AnimatedRoutes = () => {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes key={location.pathname} location={location}>
                <Route exact path='/' element={<Home imgsrc={imaAbout} imgsrcT={image} />} />
                <Route exact path='/resume' element={<Resume imgsrc={JaueryImg} imgsrcT={Envato} />} />
                <Route exact path='/portfolio' element={<Portfolio />} />
                <Route exact path='/contact' element={<Contact />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes
