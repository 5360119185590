import React from 'react'

const Timeline = (props) => {
    const data = props.data;
    return (
        <>
            <div className="timeline-wrap">
                {
                    data.slice(0).reverse().map((times, index) => {
                        const {tStatus, tDate, tName, tTitle, tText} = times;
                        return (
                            <div className="timeline-item" key={index}>
                                <h5 className={`timeline-time ${tStatus}`}>{tDate}</h5>
                                <span className='timeline-subheading'>{tName}</span>
                                <h2 className='timeline-title'>{tTitle}</h2>
                                <p>{tText}</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Timeline
