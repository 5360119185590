import React from 'react'

const Knowledge = (props) => {
    const data = props.data;
    return (
        <>
            <ul className="knowledges">
                {
                    data.map((name, index) => {
                        return (
                            <li key={index}>{name}</li>
                        )
                    })
                }
            </ul>
        </>
    )
}

export default Knowledge
