import React from 'react'

const SidebarItems = (props) => {
    const data = props.data;
    return (
        <>
            <ul>
                {
                    data.map((name,index) => {
                        return (
                            <li key={index}>{name}</li>
                        )
                    })
                }
            </ul>
        </>
    )
}

export default SidebarItems
