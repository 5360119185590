import React from 'react'
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-social">
          <ul className='social-links'>
            <li><a href="https://www.facebook.com/itsmehriday" target="blank">Twitter</a></li>
            <li><a href="https://www.facebook.com/itsmehriday" target="blank">Facebook</a></li>
            <li><a href="https://www.instagram.com/itsmehriday" target="blank"> Instagram</a></li>
          </ul>
        </div>
        <div className="footer-copyright">© 2022 All rights reserved.</div>
      </div>
    </>
  )
}

export default Footer
